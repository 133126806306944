import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(LanguageDetector)
  .init({
    fallbackLng: "en", // Fallback language if detection fails
    supportedLngs: ['en'],
    detection: {
      // Order and from where user language should be detected
      order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],

      // Keys or params to lookup language from
      lookupQuerystring: 'lng',
      lookupCookie: 'i18next',
      lookupLocalStorage: 'i18nextLng',
      lookupFromPathIndex: 0,
      lookupFromSubdomainIndex: 0,
      // Cache the user language on
      caches: ['localStorage', 'cookie'],
      excludeCacheFor: ['cimode'], // Exclude certain languages from being cached
    },
    debug: true,
    resources: {},
  });

export default i18n;

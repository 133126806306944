import { useState } from "react";
import { Link } from "react-router-dom";
import { AppRoute } from "../../routes";
import { IntercomButton } from "../intercom/IntercomButton";
import ImgLogo from "./../../assets/img/common/logo_white.svg";
import { CookieBar } from "./cookie-bar";
import { ReactComponent as ImgTPO } from "../../assets/img/home/thepropertyombudsman-logo.svg";
import InstagramIcon from "../../assets/icons/social/instagram_icon.png";
import TikTokIcon from "../../assets/icons/social/tiktok_icon.png";
import FacebookIcon from "../../assets/icons/social/facebook_icon.png";
import YouTubeIcon from "../../assets/icons/social/youtube_icon.png";
import XIcon from "../../assets/icons/social/twitter_bird_icon.png";
import LinkedInIcon from "../../assets/icons/social/linkedin_icon.png";

const socialLinks = [
  {
    href: "https://www.instagram.com/propnerdglobal/",
    icon: InstagramIcon,
    alt: "Instagram",
  },
  {
    href: "https://www.tiktok.com/@propnerdglobal?lang=en",
    icon: TikTokIcon,
    alt: "TikTok",
  },
  {
    href: "https://www.facebook.com/profile.php?id=61562283367605",
    icon: FacebookIcon,
    alt: "Facebook",
  },
  {
    href: "https://www.youtube.com/channel/UCscP2BoAPTXXCWvGQjP4oTQ",
    icon: YouTubeIcon,
    alt: "YouTube",
  },
  { href: "https://x.com/PropNerdGlobal", icon: XIcon, alt: "X (Twitter)" },
  {
    href: "https://www.linkedin.com/in/propnerd-marketing-89784133b/",
    icon: LinkedInIcon,
    alt: "LinkedIn",
  },
];

export function Footer() {
  const [showIntercom, setShowIntercom] = useState(false);

  return (
    <footer>
      <div className="footer-content">
        <div className="logo">
          <img src={ImgLogo} alt="PropNerd" height={40} />
          {/* <h4><b>Fast, Easy, Secure</b></h4> */}
          <img
            src={ImgLogo}
            alt="PropNerd"
            height={40}
            style={{ visibility: "hidden" }}
          />
        </div>
        <div className="rows">
          <div className="links">
            <h3>PropNerd</h3>
            <Link to={AppRoute.About}>About</Link>
            <Link to={AppRoute.PropertyMarketplace}>Properties</Link>
            <Link to={AppRoute.Services}>Services</Link>
            <a href={process.env.REACT_APP_BLOG_URL}>LearnHub</a>
            <Link to={AppRoute.Faq}>FAQs</Link>
            <Link to={AppRoute.CustomerSupport}>Customer Support</Link>
          </div>
          <div className="address">
            <h3>Office Address</h3>
            <p>23 Berkeley Square</p>
            <p>Mayfair, London</p>
            <p>United Kingdom, W1J6HE</p>
          </div>
          <div className="contact">
            <h3>Contact</h3>
            <p>+44(0)203 307 3187</p>
            <p>info@propnerd.io</p>
            <p>M-F: 09.00 - 17.00</p>
          </div>
          <div className="logos">
            <ImgTPO width="155" height="54" />
          </div>
        </div>
        <div className="info">
          <a>
            &copy; {new Date().getFullYear()} PropNerd. All rights reserved.
          </a>
          <Link to={AppRoute.Disclaimer}>Disclaimer</Link>
          <Link to={AppRoute.Compliance}>Compliance</Link>
          <Link to={AppRoute.Cookie}>Cookie Policy</Link>
          <Link to={AppRoute.Terms}>Terms of Service</Link>
          <Link to={AppRoute.Privacy}>Privacy Policy</Link>
        </div>
        <div
          className="social-links"
          style={{ display: "flex", gap: "13px", marginBottom: "0px" }}
        >
          {socialLinks.map(({ href, icon, alt }) => (
            <a key={alt} href={href} target="_blank" rel="noopener noreferrer">
              <img
                src={icon}
                alt={alt}
                style={{ width: "19px", height: "19px" }}
              />
            </a>
          ))}
        </div>
        <div className="disclaimer" style={{ marginTop: "0px" }}>
          PropNerd is not a broker-dealer, and all investments are subject to
          regulatory review. Investors are required to undergo identity
          verification through KYC processes and comply with AML laws. Failure
          to do so may result in account restrictions. This process is essential
          to ensure the security and legality of all transactions on the
          platform. PropNerd does not provide investment advice, endorsements,
          or recommendations with respect to any of the properties listed on
          this site. Nothing on this website should be construed as an offer to
          sell, solicitation of an offer to buy, or a recommendation in respect
          of a security. You are solely responsible for deciding whether any
          investment, investment strategy or related transaction is appropriate
          for you based on your personal investment objectives, financial
          circumstances, and risk tolerance. Remember, the value of any
          investment can go up or down. You should consult with licensed legal
          professionals and investment advisors for any legal, tax, insurance,
          or investment advice before making an investment decision. PropNerd
          does not guarantee any investment performance, outcome or return of
          capital for any investment opportunity posted on this site. By
          accessing this site and any pages thereof, you agree to be bound by
          our <Link to={AppRoute.Terms}>Terms of Service</Link> and{" "}
          <Link to={AppRoute.Privacy}>Privacy Policy</Link>.
        </div>
        <CookieBar
          onAccept={() => {
            setShowIntercom(true);
          }}
        />
        {showIntercom && <IntercomButton type="yellow" />}
      </div>
    </footer>
  );
}

import {
  Navigate,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";

import { AboutPage } from "./pages/about/about";
// import { AccountLoginPage } from "./pages/account/login/login";
import { QualifiedInvestorPage } from "./pages/account/qualifiedInvestor/qualified-investor";
// import { AccountRegisterPage } from "./pages/account/register/register";
import AccountRequestDeletionPage from "./pages/account/request-deletion/request-deletion";
import { AccountVerifyPage } from "./pages/account/verify/verify";
import { CompliancePage } from "./pages/compliance/compliance";
import { CookiePolicyPage } from "./pages/cookie/cookie";
import { CustomerSupportPage } from "./pages/customer-support/customer-support";
import { DisclaimerPage } from "./pages/disclaimer/disclaimer";
import { Error404Page } from "./pages/error/404";
import { FaqPage } from "./pages/faq/faq";
import { HomePage } from "./pages/home/home";
import { HowItWorksPage } from "./pages/how/how";
import { PrivacyPage } from "./pages/privacy/privacy";
import { PropertyMarketplacePage } from "./pages/properties/marketplace/marketplace";
import { PropertyDetailsPage } from "./pages/properties/marketplace/property-page";
import { PropertySubmitPage } from "./pages/properties/submit/submit";
import { RisksPage } from "./pages/risks/risks";
import { ServicesPage } from "./pages/services/services";
import { TermsPage } from "./pages/terms/terms";
import { WelcomePage } from "./pages/welcome/welcome";

const investorPortalUrl = process.env.REACT_APP_INVESTOR_PORTAL_URL ?? "";

export const AppRoute = {
  Home: "/",
  About: "/about",
  HowItWorks: "/how-it-works",
  Property: "/property/:slug/:id",
  PropertyDetails: "/property-details/:slug/:id",
  PropertyMarketplace: "/property/marketplace",
  PropertySubmit: "/property/submit",
  Services: "/services",
  Faq: "/faq",
  CustomerSupport: "/customer-support",
  AccountLogin: "/account/login",
  AccountRegister: `${investorPortalUrl}/account/register`,
  AccountVerify: "/account/register/verify",
  AccountRequestDeletion: "/account/request-deletion",
  Terms: "/terms",
  Privacy: "/privacy",
  Disclaimer: "/disclaimer",
  Compliance: "/compilance",
  Risk: "/risks",
  Cookie: "/cookie-policy",
  Welcome: "/welcome",
  QualifiedInvestor: "/qualified-investor",
  NotFound: "/404",
};

const router = createBrowserRouter(
  createRoutesFromElements([
    <Route path={AppRoute.Home} element={<HomePage />} />,
    <Route path={AppRoute.About} element={<AboutPage />} />,
    <Route path={AppRoute.HowItWorks} element={<HowItWorksPage />} />,
    <Route
      path={AppRoute.PropertyMarketplace}
      element={<PropertyMarketplacePage />}
    />,
    <Route path={AppRoute.Property} element={<PropertyDetailsPage />} />,
    <Route path={AppRoute.PropertySubmit} element={<PropertySubmitPage />} />,
    <Route path={AppRoute.Services} element={<ServicesPage />} />,
    <Route path={AppRoute.Faq} element={<FaqPage />} />,
    <Route path={AppRoute.CustomerSupport} element={<CustomerSupportPage />} />,
    // <Route path={AppRoute.AccountLogin} element={<AccountLoginPage />} />,
    // <Route path={AppRoute.AccountRegister} element={<AccountRegisterPage />} />,
    <Route path={AppRoute.AccountVerify} element={<AccountVerifyPage />} />,
    <Route
      path={AppRoute.AccountRequestDeletion}
      element={<AccountRequestDeletionPage />}
    />,
    <Route path={AppRoute.Terms} element={<TermsPage />} />,
    <Route path={AppRoute.Privacy} element={<PrivacyPage />} />,
    <Route path={AppRoute.Disclaimer} element={<DisclaimerPage />} />,
    <Route path={AppRoute.Compliance} element={<CompliancePage />} />,
    <Route path={AppRoute.Risk} element={<RisksPage />} />,
    <Route path={AppRoute.Cookie} element={<CookiePolicyPage />} />,
    <Route path={AppRoute.Welcome} element={<WelcomePage />} />,
    <Route
      path={AppRoute.QualifiedInvestor}
      element={<QualifiedInvestorPage />}
    />,
    <Route path={AppRoute.NotFound} element={<Error404Page />} />,
    <Route path="*" element={<Navigate to={AppRoute.NotFound} />} />,
  ])
);

export const AppRoutes = () => (
  <>
    <RouterProvider router={router} />
  </>
);
